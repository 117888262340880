<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <div else>
      <sections-slider
        v-if="$route.params.items == `Suggested For You`"
        :list="itemSuggestions($route.params.market)"
        :subTitle="$route.params.items"
        :hasBackground="true"
        :hasButton="false"
        :showTitle="true"
        :withoutSlider="true"
        class="suggested-slider suggested-4box"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div w-100"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>

      <sections-slider
        v-if="$route.params.items == `What's Trending`"
        :list="trendingItems($route.params.market)"
        :subTitle="$route.params.items"
        :hasBackground="true"
        :hasButton="false"
        :showTitle="true"
        :withoutSlider="true"
        class="suggested-slider suggested-4box"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div w-100"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "market-listing",
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapGetters({
      itemSuggestions: "market/getItemSuggestions",
      trendingItems: "market/getTrendingItems",
    }),
  },
  async mounted() {
    this.isLoading = true;
    const market = {
      path: this.$route.params.items,
      location: this.currentPosition?.data?.City,
    };
    await this.$store.dispatch("market/fetchAllItems", market);
    this.isLoading = false;
  },
};
</script>
<style scoped>
.suggested-slider .item-box {
  margin: 0 12px;
  width: 266px;
  display: block;
}
.suggested-slider .suggester-div:hover {
  border: 1px solid #272727;
  box-shadow: 0px 8px 15px 0px rgb(0 0 0 / 10%);
}
.market-suggested .section-slider-head {
  margin-bottom: 1rem !important;
}
@media (max-width: 768px) {
  .suggested-4box .item-box {
    width: 100%;
    margin: 0;
}
}
</style>
